/*-----------------------
    Brand Styles  
-----------------------*/

.brand-list {
    @extend %liststyle;
    li {
        margin: 0;
        text-align: center;
        a {
            display: block;
            text-align: center;
            img {
                display: inline-block;
                text-align: center;
            }
        }
    }
}


















